<template>
  <div style="height: 100%">
    <div class='titleBox'>
      <breadcrumb-nav>
        <template v-slot:firstMenu>系统管理</template>
        <template v-slot:secondMenu>功能管理</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
          功能管理
      </div>
    </div>
    <div class="radioBox">
      <el-card style="height: 100%;marginRight: 15px">
          <!-- 添加员工 -->
           <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" default-expand-all></el-tree>
        </el-card>
        
        <el-card style="height: 100%;width: 100%;overflow:auto;">
          <add-modal :id='nodeId'  @role-list='updateRoleList'/>
          <el-table highlight-current-row :data="pathList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
            <el-table-column prop="menuName" label="功能名称"></el-table-column>
            <el-table-column prop="url" label="路径"></el-table-column>
            <el-table-column label="操作" width="200px" fixed="right">
              <template slot-scope="scope">
                <div style="display: flex;">
                  <div>
                    <edit-modal :data='scope.row' @role-list='updateRoleList'/>
                  </div>
                  <div style="marginLeft: 15px">
                    <delete-modal :role-id ='scope.row.nodeId' @role-list='updateRoleList'  />
                  </div>       
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    
  </div>
</template>

<script>

  // 导入公共组件
  import BreadcrumbNav from "../common/BreadcrumbNav";

  // 导入发送网络请求的函数
  import {getRightsListRequest} from 'network/rights'
import AddModal from './FunctionComponents/AddModal.vue';
import EditModal from './FunctionComponents/EditModal.vue';
import DeleteModal from './FunctionComponents/DeleteModal.vue';


  export default {
    name: "Roles",
    components:{
      BreadcrumbNav,
      AddModal,
        EditModal,
        DeleteModal,
    },
    data() {
      return {
        rolesList: [],
        queryInfo: {
          query: "",
          pageNum: 1,
          pageSize: 10
        },
        total: 0,
        data: [],
        defaultProps: {
          children: 'children',
          label: 'menuName'
        },
        pathList: [],
        nodeId: '',
      }
    },
    created() {
      this.getRightsList();
    },
    methods: {
      getRightsList() {
        getRightsListRequest({
          // enterpriseId: localStorage.getItem('enterpriseId')
        }).then(res => {
          console.log(res);
          let result = res.data;
          if (result.code !== 200) {
            this.$message({
              message: "获取权限列表失败",
              type: "error"
            });
            return;
          }
          this.data = result.data;
        })
      },

      handleNodeClick(data) {
        console.log(data);
        this.nodeId = data.parentNodeId
        getRightsListRequest({
          enterpriseId: localStorage.getItem('enterpriseId'),
          nodeId: data.parentNodeId,
        }).then(res => {
          console.log(res);
          let result = res.data;
          if (result.code !== 200) {
            this.$message({
              message: "获取权限列表失败",
              type: "error"
            });
            return;
          }
          this.pathList = result.data;
        })
      },

      getOldNodeList(){
        getRightsListRequest({
          enterpriseId: localStorage.getItem('enterpriseId'),
          nodeId: this.nodeId,
        }).then(res => {
          console.log(res);
          let result = res.data;
          if (result.code !== 200) {
            this.$message({
              message: "获取权限列表失败",
              type: "error"
            });
            return;
          }
          this.pathList = result.data;
        })
      },

      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        this.getOldNodeList();
      }
    }
  }
</script>
<style scoped>
.titleBox{
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
}
.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  height: 100%;
}
.TreeBox{
  width: 200px;
  margin-right: 10px;
  
}
</style>
