<template>
  <el-button type="text"  @click="removeRoleById(roleId)">删除</el-button>
</template>

<script>
  import {deleteFunction} from 'network/plan'

  export default {
    name: "DeleteModal",
    props: {
      roleId: {
        type: String,
      }
    },
    methods: {
      // 根据ID删除用户
      removeRoleById(id) {
        console.log(id);
        this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteFunction(
            {
              nodeId: this.roleId
            }
          ).then(res => {
            let result = res.data;

            if (result.code !== 200) {
              return this.alertMessage('删除失败', 'error');
            }

            this.alertMessage('删除成功', 'success');

            this.$emit('role-list');
          });
        }).catch(() => {
          this.alertMessage('已取消删除','info');
        });
      }
    }
  }
</script>

<style scoped>

</style>
